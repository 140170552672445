<template>
  <section
    class="
      side-nav
      bg-legend-black
      dark:bg-dark-mode-secondary
      h-screen
      xl:sticky
      lg:sticky
      md:absolute
      absolute
      top-0
      flex-shrink-0
      z-40
    "
  >
    <div>
      <div class="mt-9 mb-12 w-full relative">
        <img
          v-if="sideNavCollapsed"
          src="@/assets/images/legend-pay-logo-mini.svg"
          alt="back to home page"
          class="mx-auto cursor-pointer"
          @click="$router.push('/')"
        />
        <img
          v-else
          src="@/assets/images/legend-pay-logo-light.svg"
          alt="back to home page"
          class="mx-auto cursor-pointer"
          @click="$router.push('/')"
        />
        <div
          class="
            hamburger-icon
            w-9
            h-9
            bg-primary-color
            rounded-full
            flex
            items-center
            justify-center
            absolute
            top-8
            -right-5
            border-2 border-white
            cursor-pointer
            z-30
          "
          @click="showMenu"
        >
          <img
            v-if="!sideNavCollapsed"
            src="@/assets/images/close-icon.svg"
            alt="collapse navigation bar"
          />
          <img
            v-else
            src="@/assets/images/hamburger-logo-white.svg"
            alt="open navigation bar"
          />
        </div>
      </div>
      <div class="text-white text-sm">
        <router-link
          :to="{ name: 'Home' }"
          class="flex px-12 py-4"
          :class="{
            'bg-legend-black-interaction': $route.name === 'Home',
          }"
        >
          <img
            src="@/assets/images/dashboard-icon-light.svg"
            alt="go to dashboard"
          />
          <span class="nav-item animate ml-5 flex-shrink-0 hidden"
            >Dashboard</span
          >
        </router-link>
        <router-link
          :to="{ name: 'Payments' }"
          class="flex px-12 py-4"
          :class="{
            'bg-legend-black-interaction': $route.name === 'Payments',
          }"
        >
          <img src="@/assets/images/paymets.svg" alt="go to dashboard" />
          <span class="nav-item animate ml-5 flex-shrink-0 hidden"
            >My Payments</span
          >
        </router-link>
        <!-- <router-link
          :to="{ name: 'Subscriptions' }"
          class="flex px-12 py-4"
          :class="{
            'bg-legend-black-interaction': $route.name === 'Subscriptions',
          }"
        >
          <img
            src="@/assets/images/subscription-icon-light.svg"
            alt="go to subscriptions"
          />
          <span class="nav-item animate ml-5 flex-shrink-0 hidden"
            >My Subscription</span
          >
        </router-link> -->
        <router-link
          :to="{ name: 'Transactions' }"
          class="flex px-12 py-4"
          :class="{
            'bg-legend-black-interaction': $route.name === 'Transactions',
          }"
        >
          <img
            src="@/assets/images/transaction-icon-light.svg"
            alt="go to transactions"
          />
          <span class="nav-item animate ml-5 flex-shrink-0 hidden"
            >My Transactions</span
          >
        </router-link>
        <router-link
          :to="{ name: 'Purchases' }"
          class="flex px-12 py-4"
          :class="{
            'bg-legend-black-interaction': $route.name.includes('Purchase'),
          }"
        >
          <img src="@/assets/images/shop-icon-light.svg" alt="go to shop" />
          <span class="nav-item animate ml-5 flex-shrink-0 hidden"
            >Purchases</span
          >
        </router-link>
        <router-link
          v-if="user && user.is_family_wallet"
          :to="{ name: 'Family' }"
          class="flex px-12 py-4"
          :class="{
            'bg-legend-black-interaction': $route.name === 'Family',
          }"
        >
          <img src="@/assets/images/family.svg" alt="faily wallet" />
          <span class="nav-item animate ml-5 flex-shrink-0 hidden">Family</span>
        </router-link>
      </div>
    </div>
    <div
      v-show="!sideNavCollapsed"
      class="
        bg-legend-black-interaction
        rounded
        px-3
        py-4
        text-white
        font-semibold
        text-sm
        mx-3
        absolute
        bottom-3
      "
    >
      <div v-if="percentageCompletion < 100">
        <div class="mb-2">{{ percentageCompletion }}% Complete</div>
        <div class="bg-gray-600 rounded-full">
          <div
            id="progress-bar"
            class="bg-white rounded-full"
            style="height: 6px"
          ></div>
        </div>
        <div class="mt-2">
          Your Profile info is
          {{ percentageCompletion === 100 ? 'Complete' : 'Incomplete' }} .
        </div>
      </div>
      <div v-else>
        <div
          style="
            background: rgba(255, 255, 255, 0.1);
            height: 56px;
            font-size: 16px;
          "
          class="flex"
        >
          <img
            src="@/assets/images/gift.svg"
            alt="Refer a friend"
            style="width: 30px; padding-left: 10px"
          />
          <span style="padding: 20px 10px">Refer a friend</span>
        </div>
        <div>
          <div style="width: 180px; text-align: center; padding: 15px 0">
            You’ll get <b>2 Points</b> when your friend makes their first
            transaction.
          </div>
          <button
            @click="copyToClipboard('.link')"
            style="
              width: 182px;
              height: 40px;
              left: 24px;
              top: 783px;
              background: rgba(255, 255, 255, 0.1);
              border-radius: 4px;
            "
          >
            {{ this.copyMessage }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Sidenav',
  mounted() {
    if (this.user) {
      this.onViewportResize()
    }

    window.addEventListener('resize', () => {
      this.onViewportResize()
    })
  },
  watch: {
    $route() {
      if (window.innerWidth < 1024 && !this.sideNavCollapsed) {
        this.collapsNavBarMobile()
      }
    },
  },
  data() {
    return {
      sideNavCollapsed: true,
      copyMessage: 'Copy Invite Link',
    }
  },
  computed: {
    ...mapGetters(['user']),
    percentageCompletion() {
      let profileCompletion = 0

      const items = [
        'city',
        'country',
        'email',
        'first_name',
        'last_name',
        'phone',
        'state',
        'street_name',
        'street_number',
        'username',
      ]

      const node = document.querySelector('#progress-bar')

      for (let key in this.user) {
        if (items.includes(key) && this.user[key].length) {
          profileCompletion += 10
        }
      }
      if (node && !this.sideNavCollapsed) {
        node.style.width = `${profileCompletion}%`
      }
      return profileCompletion
    },
  },
  methods: {
    onViewportResize() {
      const nodes = document.querySelectorAll('.nav-item')
      const sideNav = document.querySelector('.side-nav')
      const hamburger = document.querySelector('.hamburger-icon')

      if (window.innerWidth < 1024) {
        sideNav.classList.add('-ml-64')
        hamburger.classList.add('-right-16')
        hamburger.classList.add('-top-2')
        nodes.forEach((node) => {
          node.classList.remove('hidden')
        })
      } else {
        sideNav.classList.remove('-ml-64')
        hamburger.classList.remove('-right-16')
        hamburger.classList.add('-right-5')
        hamburger.classList.remove('-top-2')
        hamburger.classList.add('top-8')
        nodes.forEach((node) => {
          node.classList.add('hidden')
        })
      }
    },
    collapsNavBar() {
      const nodes = document.querySelectorAll('.nav-item')
      nodes.forEach((node) => {
        if ([...node.classList].includes('hidden')) {
          node.classList.remove('hidden')
          this.sideNavCollapsed = false
        } else {
          node.classList.add('hidden')
          this.sideNavCollapsed = true
        }
      })
    },
    showMenu() {
      if (window.innerWidth < 1024) {
        this.collapsNavBarMobile()
      } else {
        this.collapsNavBar()
      }
    },
    collapsNavBarMobile() {
      const sideNav = document.querySelector('.side-nav')
      const hamburger = document.querySelector('.hamburger-icon')

      if ([...sideNav.classList].includes('-ml-64')) {
        this.sideNavCollapsed = false
        sideNav.classList.remove('-ml-64')
        sideNav.classList.add('ml-0')
        hamburger.classList.remove('-right-16')
        hamburger.classList.add('-right-5')
        hamburger.classList.remove('-top-2')
        hamburger.classList.add('top-8')
      } else {
        sideNav.classList.remove('ml-0')
        sideNav.classList.add('-ml-64')
        hamburger.classList.remove('-right-5')
        hamburger.classList.add('-right-16')
        hamburger.classList.remove('top-8')
        hamburger.classList.add('-top-2')
        this.sideNavCollapsed = true
      }
    },
    sideNavEvent(event) {
      const sideNav = document.querySelector('.side-nav')
      const hamburger = document.querySelector('.hamburger-icon')

      const tagName = event.target.tagName.toLowerCase()
      if (tagName.toLowerCase() === 'span' || tagName === 'a') {
        sideNav.classList.remove('ml-0')
        sideNav.classList.add('-ml-64')
        hamburger.classList.remove('-right-5')
        hamburger.classList.add('-right-16')
        hamburger.classList.remove('top-8')
        hamburger.classList.add('-top-2')
        this.sideNavCollapsed = true
      }
    },
    copyToClipboard() {
      const text = window.location.host + '/' + this.user.username
      navigator.clipboard.writeText(text).then(() => {
        this.copyMessage = 'Copied!'

        setTimeout(() => {
          this.copyMessage = 'Copy Invite Link'
        }, 5000)
      })
    },
  },
}
</script>
