var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"\n    side-nav\n    bg-legend-black\n    dark:bg-dark-mode-secondary\n    h-screen\n    xl:sticky\n    lg:sticky\n    md:absolute\n    absolute\n    top-0\n    flex-shrink-0\n    z-40\n  "},[_c('div',[_c('div',{staticClass:"mt-9 mb-12 w-full relative"},[(_vm.sideNavCollapsed)?_c('img',{staticClass:"mx-auto cursor-pointer",attrs:{"src":require("@/assets/images/legend-pay-logo-mini.svg"),"alt":"back to home page"},on:{"click":function($event){return _vm.$router.push('/')}}}):_c('img',{staticClass:"mx-auto cursor-pointer",attrs:{"src":require("@/assets/images/legend-pay-logo-light.svg"),"alt":"back to home page"},on:{"click":function($event){return _vm.$router.push('/')}}}),_c('div',{staticClass:"\n          hamburger-icon\n          w-9\n          h-9\n          bg-primary-color\n          rounded-full\n          flex\n          items-center\n          justify-center\n          absolute\n          top-8\n          -right-5\n          border-2 border-white\n          cursor-pointer\n          z-30\n        ",on:{"click":_vm.showMenu}},[(!_vm.sideNavCollapsed)?_c('img',{attrs:{"src":require("@/assets/images/close-icon.svg"),"alt":"collapse navigation bar"}}):_c('img',{attrs:{"src":require("@/assets/images/hamburger-logo-white.svg"),"alt":"open navigation bar"}})])]),_c('div',{staticClass:"text-white text-sm"},[_c('router-link',{staticClass:"flex px-12 py-4",class:{
          'bg-legend-black-interaction': _vm.$route.name === 'Home',
        },attrs:{"to":{ name: 'Home' }}},[_c('img',{attrs:{"src":require("@/assets/images/dashboard-icon-light.svg"),"alt":"go to dashboard"}}),_c('span',{staticClass:"nav-item animate ml-5 flex-shrink-0 hidden"},[_vm._v("Dashboard")])]),_c('router-link',{staticClass:"flex px-12 py-4",class:{
          'bg-legend-black-interaction': _vm.$route.name === 'Payments',
        },attrs:{"to":{ name: 'Payments' }}},[_c('img',{attrs:{"src":require("@/assets/images/paymets.svg"),"alt":"go to dashboard"}}),_c('span',{staticClass:"nav-item animate ml-5 flex-shrink-0 hidden"},[_vm._v("My Payments")])]),_c('router-link',{staticClass:"flex px-12 py-4",class:{
          'bg-legend-black-interaction': _vm.$route.name === 'Transactions',
        },attrs:{"to":{ name: 'Transactions' }}},[_c('img',{attrs:{"src":require("@/assets/images/transaction-icon-light.svg"),"alt":"go to transactions"}}),_c('span',{staticClass:"nav-item animate ml-5 flex-shrink-0 hidden"},[_vm._v("My Transactions")])]),_c('router-link',{staticClass:"flex px-12 py-4",class:{
          'bg-legend-black-interaction': _vm.$route.name.includes('Purchase'),
        },attrs:{"to":{ name: 'Purchases' }}},[_c('img',{attrs:{"src":require("@/assets/images/shop-icon-light.svg"),"alt":"go to shop"}}),_c('span',{staticClass:"nav-item animate ml-5 flex-shrink-0 hidden"},[_vm._v("Purchases")])]),(_vm.user && _vm.user.is_family_wallet)?_c('router-link',{staticClass:"flex px-12 py-4",class:{
          'bg-legend-black-interaction': _vm.$route.name === 'Family',
        },attrs:{"to":{ name: 'Family' }}},[_c('img',{attrs:{"src":require("@/assets/images/family.svg"),"alt":"faily wallet"}}),_c('span',{staticClass:"nav-item animate ml-5 flex-shrink-0 hidden"},[_vm._v("Family")])]):_vm._e()],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.sideNavCollapsed),expression:"!sideNavCollapsed"}],staticClass:"\n      bg-legend-black-interaction\n      rounded\n      px-3\n      py-4\n      text-white\n      font-semibold\n      text-sm\n      mx-3\n      absolute\n      bottom-3\n    "},[(_vm.percentageCompletion < 100)?_c('div',[_c('div',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.percentageCompletion)+"% Complete")]),_vm._m(0),_c('div',{staticClass:"mt-2"},[_vm._v(" Your Profile info is "+_vm._s(_vm.percentageCompletion === 100 ? 'Complete' : 'Incomplete')+" . ")])]):_c('div',[_vm._m(1),_c('div',[_vm._m(2),_c('button',{staticStyle:{"width":"182px","height":"40px","left":"24px","top":"783px","background":"rgba(255, 255, 255, 0.1)","border-radius":"4px"},on:{"click":function($event){return _vm.copyToClipboard('.link')}}},[_vm._v(" "+_vm._s(this.copyMessage)+" ")])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-gray-600 rounded-full"},[_c('div',{staticClass:"bg-white rounded-full",staticStyle:{"height":"6px"},attrs:{"id":"progress-bar"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex",staticStyle:{"background":"rgba(255, 255, 255, 0.1)","height":"56px","font-size":"16px"}},[_c('img',{staticStyle:{"width":"30px","padding-left":"10px"},attrs:{"src":require("@/assets/images/gift.svg"),"alt":"Refer a friend"}}),_c('span',{staticStyle:{"padding":"20px 10px"}},[_vm._v("Refer a friend")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"180px","text-align":"center","padding":"15px 0"}},[_vm._v(" You’ll get "),_c('b',[_vm._v("2 Points")]),_vm._v(" when your friend makes their first transaction. ")])}]

export { render, staticRenderFns }